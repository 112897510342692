body {
  background-color: white;
  max-width: 1500px;
  margin: 0px auto;

  border: px solid gold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif;
}

.banner {
  height: 200px;
  width: 100%;
  border: px solid red;
  overflow: hidden;
}
.img-banner {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.left-menu {
  border: 0px solid black;
  margin-right: 0px;
  float: left;
  width: 300px;
  min-width: 250px;
     background: linear-gradient(to right, rgb(136 136 136 / 8%), rgb(237 237 237 / 55%));
  height: calc(100vh - 50px);
  overflow-x: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", sans-serif;
  /* linear-gradient(to right, rgba(0, 0, 0, 0.05) 80%, white); */
}
.ch
{
    width: 15px;
    height: 15px;
    margin-top: 0px;
    opacity: 0.6;
}

.main-page {
  display: flex;
  justify-content: center;
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.active-tree-item {
    border-radius: 3px;
    background-color: rgb(19 63 139 / 35%) !important;
    font-weight: 500;
    /* padding: 0 0 2px 0px; */
}
.active-tree-item .tree-item-text
{
  color: black !important;
  font-weight: 500;
}

.tree-item .btn-expand {
  border: 0;
  border-radius: 3px;
  width: 22px;
outline: none;
    background-color: transparent;
  
      display: flex;
    justify-content: center;
    align-items: center;
     
}
 
.btn-expand :hover {
 cursor: pointer;
  background-color: rgba(0, 0, 0, 0.15);
 /* margin: 4px 0; */
 
}
.tree-item .btn-add {
  border: 0;
  border-radius: 3px;
  width: 22px;

  background-color: rgba(0, 0, 0, 0.05);
  visibility: hidden;
  display: none;
}

.btn-add:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.tree-name-and-buttons:hover .btn-add,
.tree-item-single:hover .btn-add {
  visibility: visible;
  display: inline-block;
  cursor: pointer;
}

.tree-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1px 0px 0px 0px;
  position: relative;
  cursor: pointer;
}

summary:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 4px;
}
.tree-item-single:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 4px;
}

.tree-folder {
  padding-left: 16px;
  width: calc(100% - 20px);
}
/* Draw Indentation lines */
/* .tree-folder:hover::before { */
  .tree-folder::before {
  content: "";
  position: absolute;
  top: 27px;
  left: 28px;
  height: calc(100% - 34px);
  width: 1px;
  border-left: 1px dotted grey;
}

.tree-item-single {
  padding-left: 16px;
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tree-item-buttons {
  white-space: nowrap;

  align-self: center;
  display: flex;
  align-items: center;
  column-gap: 2px;
  padding: 0 2px;
}

.tree-item-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
   
  padding: 0 0 0 5px;
  color: #3e3e3e96;
  font-size: 14px;
    
    font-weight: 500
}

summary {
  width: 100%;
}

span.tree-name-and-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

details summary {
  display: inline-block;
}

/* Active/non active borders for images */
.f1 {
  border: 4px solid dodgerblue !important;
  padding: 5px;
}
.f2 {
  border: 1px dotted grey;
  padding: 5px;
}

/* Prevent Body scroll */
.my-modal {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.my-modal::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(45deg, rgb(255, 0, 255, 0.2), rgb(102, 51, 153, 0.2), rgb(30, 144, 255, 0.2), rgb(0, 128, 0, 0.2));
}

.drag-hover
{
  background-color: rgba(0,0,155,0.2);
}
.forbidden-drop {
  border: 2px dashed #ff0000;
  cursor: not-allowed;
}


































/* Gallery */
.gallery1
{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
 border: 1px solid red;
 width: 100%;
 padding: 4px;
}

 .toolbar1
{
  width: 100;
  background-color: rgba(0,0,0,0.06);
  margin-bottom: 5px;
  border-bottom: 2px solid grey;
  height: 30px;
}

.need-save {
 
  font-style: italic;
  color: green;
}
.tree-item-icon
{
  width:16px;
  height: 16px;
  /* padding: 2px 0px; */
}

.div-icons
{
  width: 300px;
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
 align-items: center;
 
}

.icon-preview{
   width:24px;
  height: 24px;
  margin: 3px;
}
.icon-preview:hover{
   scale: 1.2;
   cursor: pointer;
   background-color: rgb(18, 119, 252);
}

.left-menu-parent #div_search
{
  padding: 1px 8px 1px 1px;
}

.left-menu-parent .searchbox
{
  width: 100%;
  padding: 2px;
  border: 1px solid grey;
  
  outline: none;
}

 .left-menu::-webkit-scrollbar {
        width: 8px; /* Set the width of the scrollbar track */
    }

    /* Define the scrollbar thumb color */
    .left-menu::-webkit-scrollbar-thumb {
        background-color: #888888; /* Set the color of the scrollbar thumb */
        border-radius: 4px; /* Add rounded corners to the scrollbar thumb */
    }

    /* Define the scrollbar track color */
    .left-menu::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Set the color of the scrollbar track */
        border-radius: 4px; /* Add rounded corners to the scrollbar track */
    }



.tree-item-icon:hover
{
  background-color: rgba(0, 0, 0, 0.05);
}
/* Drag drop on separators */
.tree-item-separator
{
height: 3px;
background-color: transparent;
/* (29, 29, 124, 0.534); */
content: attr(data-x);
   transition: all ease-in-out 0.1s;
}
 .tree-item-separator.drag-hover
{
  background-color: rgba(0,0,0,0.5);
transition: all ease-in-out 0s;
}

.dragging{
  opacity: 0.2;
  cursor: pointer;
}


.tree-item-separator.forbidden-drop
{
  background-color: red;
}