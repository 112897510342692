.contentEditable {
  border: 0px solid black;
  min-height: 200px;
  padding-left: 50px;
  outline: none;
  overflow-y: auto;
  height: 70vh;
}

.bold-text {
  font-weight: 600;
}

.italic-text {
  font-style: italic;
}

.stroke-text {
  text-decoration: line-through;
}

.under-text {
  text-decoration: underline;
}

.textUnderlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-heading-h1 {
  font-size: 30px;
  font-weight: 400;
  margin: 4px 0;
}
.editor-heading-h2 {
  font-size: 25px;
  font-weight: 500;
  margin: 25px 2px 8px 5px;
}
.editor-heading-h3 {
  font-size: 22px;
  font-weight: 500;
  margin: 6px 5px 6px 5px;
}
.editor-heading-h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 6px 5px 6px 5px;
}
.editor-heading-h5 {
  font-size: 22px;

  margin: 4px 0;
}

.editor-quote{
  border-left: 3px solid grey;
    padding-left: 12px;
}

.bignote {
  background-color: firebrick;
  color: white;
  font-size: 22px;
  margin: 5px;
}

.toolbar {
  background-color: white;
  color: black;
  font-size: 22px;
  margin-bottom: 5px;
}

.button-separator {
  margin: 4px;
}

.editor-paragraph {
  padding: 1px 0px;
  margin: 0px 14px;
}

.toolbar-btn {
  border: none;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 4px;
  margin: 2px;

  border-radius: 4px;
  min-width: 35px;
  height: 35px;
}
.toolbar-btn:hover {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.toolbar-btn.clean-status {
}
.toolbar-btn.dirty-status {
  background-color: darkred;
  color: white;
}
.active {
  font-weight: 900;
  background-color: rgba(0, 0, 0, 0.1);

  /* font-size: 20px; */
}
/* p{
   background-color: rgba(0,0,0,0.1);
   margin: 4px;
 } */

code
{
  background-color: lightgray;
  padding: 2px 4px;
  border-radius: 4px;
}

.editor-code {
  background-color: rgba(0, 0, 0, 0.05);
  display: block;
  padding: 12px;
  margin: 10px 10px 13px 30px;
}

.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-listItem {
  margin: 4px 0;
}
img.focused {
  outline: 6px solid rgb(60, 132, 244);
}

img.focused.draggable {
  cursor: grab;
}

img.focused.draggable:active {
  cursor: grabbing;
}
span .video-wrap {
  padding: 5px;
  margin: 4px;

  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: 4px;
}
span .video-wrap:hover {
  border: 2px dashed blue;
}

span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: 4px;
}
.img-span {
  border: 1px dashed grey;
  padding: 8px;
  margin: 8px;
}

.editor-image.position-full {
  margin: 1em 0 1em 0;
}

.editor-image.position-left {
  float: left;
  width: 50%;
  margin: 1em 1em 0 0;
}

.editor-image.position-right {
  float: right;
  width: 50%;
  margin: 1em 0 0 1em;
}
